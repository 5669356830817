import { setupWeb3,setNetwork,addEthereumAccounts,web3LoadingError } from "./actions";
import Web3 from "web3";
import { ContractUtility } from "utility";

export const loadBlockchain = async (provider,dispatch) => {
    try {
        if (provider) {
            const web3 = new Web3(provider);
            dispatch(setupWeb3(web3));
            const accounts = await web3.eth.getAccounts();
            dispatch(addEthereumAccounts(accounts));
            let network = ContractUtility.getNetwork(web3.givenProvider.chainId);
            dispatch(setNetwork({ network }));
        }
        else {
            dispatch(web3LoadingError("Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"))
        }
    }
    catch (error) {
        console.log("Error in loading Web3 = ",error);
        if (error?.code === 4001) {
            dispatch(web3LoadingError(error.message));
        }
    }
}

export const lendAsync = async (contract,accounts,token_address,token_id,duration,dailyprice,nftPrice) => {
    try {
        let receipt = await contract.methods.lease([token_address],[token_id],[1],[duration],[dailyprice],[nftPrice],[1]).send({ from: accounts[0] });
        return receipt
    }
    catch (error) {
        console.log("error",error)
        return error
    }
}

export const getLendIdAsync = async (contract) => {
    try {
        let receipt = await contract.methods.getleasingId().call();
        return receipt
    }
    catch (error) {
        console.log("error",error)
        return error
    }
}

export const rentAsync = async (contract,accounts,token_address,token_id,lending_id,duration) => {
    try {
        // let receipt = await contract.methods.rent(["0x9957f6fb41d0B557C616D4d0aAfF90a47D12B3B2"], ["1"], ["4"], ["1"]).send({ from: accounts[0] });
        let receipt = await contract.methods.rentNFT([token_address],[token_id],[lending_id],[duration]).send({ from: accounts[0] });

        return receipt
    }
    catch (error) {
        console.log("error",error)
        return error
    }
}

export const stopLendingAsync = async (contract,accounts,token_address,token_id,lending_id) => {
    try {
        let receipt = await contract.methods.cancelLeasing([token_address],[token_id],[lending_id]).send({ from: accounts[0] });
        return receipt
    }
    catch (error) {
        console.log("error",error)
        return error
    }
}


export const returnItAsync = async (contract,accounts,token_address,token_id,lending_id) => {
    try {
        let receipt = await contract.methods.endRent([token_address],[token_id],[lending_id]).send({ from: accounts[0] });
        return receipt
    }
    catch (error) {
        console.log("error",error)
        return error
    }
}

export const claimColletralAsync = async (contract,accounts,token_address,token_id,lending_id) => {
    try {
        let receipt = await contract.methods.claimCollateral([token_address],[token_id],[lending_id]).send({ from: accounts[0] });
        return receipt;
    }
    catch (error) {
        console.log("error",error)
    }
}

export const isApprovedForAll = async (contract,account,rental_address) => {
    try {
        let receipt = await contract.methods.isApprovedForAll(account,rental_address).call();
        return receipt
    }
    catch (error) {
        console.log("isApprovedForAll Error",error)
        return error
    }
}

export const getPaymentMethodAddress = async (contract,method) => {
    try {
        let receipt = await contract.methods.getPaymentToken(method).call();
        return receipt
    }
    catch (error) {
        console.log("error",error)
        return error
    }
}