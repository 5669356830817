import { useEffect,useState } from "react"
import { ContractUtility,ErrorConstant,NFTService,Protocols } from "../utility";


const moralisToOpenSea = (item) => {
    const metadata = item.metadata ? JSON.parse(item.metadata) : {};

    const t = {
        name: metadata?.name,
        image_preview_url: (metadata?.image_url || '').replace("ipfs://","https://ipfs.io/ipfs/"),
        description: metadata?.description,
        token_id: item.token_id,
        asset_contract: {
            address: item.token_address,
            name: item.name,
            schema_name: item.contract_type,
        },
        id: `${item.token_address}-${item.token_id}`
        // permalink : 
    }
    return t;
}

export const UserNFTListHook = (protocol,address) => {

    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');
    const [hasMore,setHasMore] = useState(true);
    const [filter,setFilter] = useState({
        offset: 0,
        limit: 48,
        order_direction: "desc"
    })

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true);
                let result = await NFTService.userNFTs({
                    owner: address,
                    ...filter
                });
                if (filter.offset === 0) {
                    setData(result.assets || []);
                } else {
                    setData(data.concat((result.assets || [])));
                }
                setHasMore((result.assets || []).length === filter.limit);
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }

        const moralisFetchData = async () => {
            try {
                setLoading(true);
                let result = await NFTService.moralisNFTs(address,{
                    chain: ContractUtility.getRightNetwork(protocol),
                    format: "decimal"
                });
                const temp = (result.result || []).map(item => moralisToOpenSea(item));
                setData(temp);
                setHasMore(result.total > result.page_size);
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }

        if (address) {
            switch (protocol) {
                case Protocols.ethereum.name:
                    fetchData();
                    break;
                case Protocols.polygon.name:
                case Protocols.bsc.name:
                    moralisFetchData();
                    break;

                default:
                    break;
            }
        }
    },[address,filter,protocol]);

    const pageChanged = () => {
        const temp = {
            ...filter,
            offset: filter.offset + filter.limit
        }
        setFilter({ ...temp });
    }

    return { data,loading,error,pageChanged,hasMore };
}

export const UserNFTDetailsHook = (protocol,address,tokenId,tokenAddress) => {
    const [data,setData] = useState({});
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                let result = await NFTService.byTokenAddress({
                    owner: address,
                    token_ids: [tokenId],
                    offset: 0,
                });
                if (result.assets || [].length > 0) {
                    setData((result.assets || [])[0]);
                };
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }

        const moralisFetchData = async () => {
            try {
                setLoading(true);
                let result = await NFTService.moralisByTokenAddress(tokenAddress,tokenId,{
                    chain: ContractUtility.getRightNetwork(protocol),
                    format: "decimal"
                });
                const temp = moralisToOpenSea(result);
                setData(temp);
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }

        if (protocol && address && tokenId && tokenAddress) {
            switch (protocol) {
                case Protocols.ethereum.name:
                    fetchData();
                    break;
                case Protocols.polygon.name:
                case Protocols.bsc.name:
                    moralisFetchData();
                    break;

                default:
                    break;
            }
        }

    },[address,tokenId,tokenAddress,protocol]);

    return { data,loading,error };
}

export const NFTMetadataHook = (nftUrls) => {

    const [data,setData] = useState({});
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const urls = nftUrls.filter(x => !Object.keys(data).includes(x));
                const temp = {};
                for (let i = 0; i < urls.length; i++) {
                    let result = await NFTService.metadata(urls[i])
                    temp[urls[i]] = result;
                }
                setData({
                    ...data,
                    ...temp
                })
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }
        if ((nftUrls || []).length > 0) {
            fetchData();
        }
    },[nftUrls]);

    return { data,loading,error };
}