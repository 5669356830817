
const server = process.env.REACT_APP_API_PATH
const openSeaAPI = process.env.REACT_APP_OPENSEA_API_PATH
const moralisAPI = process.env.REACT_APP_MORALIS_API_PATH;
export const APIPath = {
    server,
    openSeaAPI,
    moralisAPI,

    // Rent
    rent: 'rent',
    myrent: 'rent-dashboard',
    returnNFT: 'return-it',
    renContractAddresses: 'rent-contract-addresses',

    // Lend
    lendNFT: 'lend-nft',
    mylend: 'lend-dashboard',
    cancelLending: 'cancel-lending',
    claimNFT: 'claim-nft',

    // Contract Config
    contractConfig: 'contract-config',

    // OpenSea
    assets: 'assets'
}