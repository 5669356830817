export const CommonConstant = {
    mode: process.env.REACT_APP_MODE,
}

export const ErrorConstant = {
    default: "Something went wrong"
}

export const Protocols = {
    ethereum: {
        name: 'ethereum',

        assetUrl: {
            mainnet: "https://etherscan.io/address",
            testnet: "https://rinkeby.etherscan.io/address",
        },
        txUrl: {
            mainnet: "https://etherscan.io/tx",
            testnet: "https://rinkeby.etherscan.io/tx",
        }
    },
    bsc: {
        name: 'bsc',
        assetUrl: {
            mainnet: "https://bscscan.com/address",
            testnet: "https://testnet.bscscan.com/address",
        },
        txUrl: {
            mainnet: "https://bscscan.com/tx",
            testnet: "https://testnet.bscscan.com/tx",
        }
    },
    polygon: {
        name: 'polygon',
        assetUrl: {
            mainnet: "https://polygonscan.com/address",
            testnet: "https://mumbai.polygonscan.com/address",
        },
        txUrl: {
            mainnet: "https://polygonscan.com/tx",
            testnet: "https://mumbai.polygonscan.com/tx",
        }
    }
}

export const PaymentType = {
    USDC: 1,
    WETH: 2
}

export const PaymentMethods = [{
    text: "USDC",
    value: PaymentType.USDC
},{
    text: "WETH",
    value: PaymentType.WETH
}];