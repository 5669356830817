import { useEffect,useState } from "react";
import { CommonService,ContractUtility,ErrorConstant } from "utility";

export const ContractConfigHook = () => {

    const [data,setData] = useState(null);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const result = await CommonService.contractConfig();

                console.log(result)
                ContractUtility.setConfig(result.data)
                setData(result.data);
            } catch (error) {
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    },[]);

    return { data,loading,error };
}