import { BrowserRouter as Router,Route,Routes } from "react-router-dom";
import { HeaderBar } from "./layout";
import { ThemeProvider,createGlobalStyle } from 'styled-components';
import {
  Error404,RentScreen,RentDetailsScreen,LendScreen,LendDetailsScreen,DashboardScreen,TransactionsScreen
} from "./screens";
import { theme } from "./theme";
import { ContractConfigHook } from "hooks";
import { LoaderBar } from "components";

const GlobalStyle = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-style: normal;
    font-size: 14px;
    line-height: 1.5;
    font-family: 'Nunito Sans', sans-serif !important;
    color: ${({ theme }) => theme.colors.black};
  }

  .ml-1{
    margin-left : 0.25rem;
  }

  .mx-1{
    margin-left : 0.25rem;
    margin-right : 0.25rem;
  }

  .mx-3{
    margin : auto 0.75rem;
  }

  .my-2{
    margin-top :  0.5rem;
    margin-bottom :  0.5rem;
  }

  .my-4{
    margin : 1rem auto;
  }

  .text-center{
    text-align : center;
  }

  .m-0{
    margin : 0 !important;
  }

  .ui.container.wrapper {
    padding-top: 61px;
  }

  .ui.icon.input>input {
    border-radius: 20px;
  }
  .ui.selection.dropdown {
    border-radius: 20px;
  }
`;

function App() {

  const { loading } = ContractConfigHook();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {loading ? <LoaderBar /> : <Router>
        <HeaderBar />
        <div className="container mt-5">
          <Routes>
            <Route path="/" exact element={<RentScreen />} />
            <Route path="/rent-details/:id" element={<RentDetailsScreen />} />
            <Route path="/lend" element={<LendScreen />} />
            <Route path="/lend-details/:tokenId/:tokenAddress" element={<LendDetailsScreen />} />
            <Route path="/dashboard" element={<DashboardScreen />} />
            <Route path="/transactions" element={<TransactionsScreen />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
      </Router>}
    </ThemeProvider>
  );
}

export default App;
