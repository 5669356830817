

import { BaseService } from './base';
import { APIPath } from '../constant';

class Common {

    contractConfig = () => {
        return BaseService.get(APIPath.contractConfig);
    }

}

const CommonService = new Common();
Object.freeze(CommonService);
export { CommonService };
